import React, { useEffect, useState } from 'react'
// import Buttons from './Buttons'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Table from './Table';
import "./tablePage.css";
import ImportButton from './ImportButton';

export default function TablePage() {
    const [tables, setTables] = useState([]);
    const [refrech, setRefrech] =useState(false)
    useEffect(() => {
        const params = new URLSearchParams();
        params.set('statusList', JSON.stringify(["upload-complete"]));
        params.set('limit', "100");
        const target = '/api/tables/list?' + params.toString();
        fetch(target)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setTables(data);
            });
    }, [refrech]);
    return (
        <div className='tables-container'>
            <div className='top-div'>
                <ImportButton name={'import'} icon={<FileDownloadOutlinedIcon />} setRefrech={setRefrech}/>
                
            </div>
            <div className='table-div'>
            {
                tables.map((table, index)=> <Table key={index} tableName={table.tableName}/>)
            }
            </div>
        </div>
    )
}

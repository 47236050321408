import React from 'react'
import Buttons from "./Buttons";
import "./smartPage.css";
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
export default function SmartPage() {
  return (
        <div className='smart-container'>
            <div className='top-div'>
                <Buttons name={"export"} icon={<IosShareOutlinedIcon />} />
                <Buttons name={"edit"} icon={<ModeEditOutlineOutlinedIcon />} />
                <Buttons name={"new"} icon={<AddBoxOutlinedIcon />} />
            </div>
            <div className='table-div'>
            {/* {
                tables.map((table, index)=> <Table key={index} tableName={table.content.tableName}/>)
            } */}
            </div>
        </div>
  )
}

import "./importButton.css"

export default function ImportButton({icon, name, setRefrech}) {
    const handleChange = (e)=>{
        const endpoint = '/api/tables/upload';
        const file = e.target.files[0];
            console.log(file)
            const formData = new FormData();
            formData.append('file', file);
            formData.append('tableName', "Table test");
            formData.append('encoding', "utf-8");
            fetch(endpoint, {
              method: 'POST',
              body: formData
            })
              .then(response => JSON.parse(JSON.stringify(response)))
              .then(data => {
                console.log(data);
                setRefrech((p)=>!p)
                return (
                  alert('file uploaded successfuly')
                );
              })
              .catch(error => {
                console.error(error);
                return (
                  alert('Error uploading file')
                );
              });
      }
  return (
    <div className='button-input'>
        <input id='fileInput' type="file" accept='.csv' onChange={(e)=>handleChange(e)} />
        <button className="btn" >{icon}{name}</button>
    </div>
  )
}

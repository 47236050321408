import "./keyInput.css";

export default function KeyInput({handleChange, value}) {
   
  return (
    <div className="input-container">
        <input className="key-input" type="text" id="key-input" value={value} onChange={(e)=>{handleChange(e.target.value)}}/>
        
    </div>
  )
}
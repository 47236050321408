import "./editST.css";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import Buttons from "./Buttons";
import DropDown from "./DropDown";
import { useEffect, useState } from "react";
export default function EditSmartTable() {
    const [tables, setTables] = useState([])
    const [selectedTable, setSelectedTable] = useState("")
    useEffect(() => {
        const params = new URLSearchParams();
        params.set('statusList', JSON.stringify(["convert-complete"]));
        params.set('limit', "100");
        const target = '/api/tables/list?' + params.toString();
        fetch(target)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setTables(
                    data.map((d) => ({ status: 0, data: d }))
                );
            });
    }, [])

    const handleClick = () => {
        setTables(prevTables => {
            const newTables = [...prevTables];
            console.log(newTables)
            newTables.find(t => t.data.tableId === selectedTable).status = 1;
            return newTables;
        })
    }

return (
    <div className="edit-st-container">
        <div className='top-div'>
            <DropDown
                options={[{ title: "Choose Tables", value: "" }].concat(tables.filter(t => t.status === 0).map(table => ({ title: table.data.tableName, value: table.data.tableId })))}
                value={selectedTable}
                onChange={(e) => {setSelectedTable(e.target.value)}}
            />
            <Buttons name={"attach table"} icon={<InsertLinkOutlinedIcon />} onclick={handleClick} />
            <Buttons name={"save smart table"} icon={<SaveOutlinedIcon />} />
        </div>
    </div>
)
}

import "./tableInfo.css";

export default function TableInfo({ options, selectedTag, disabled, onClick }) {
    return (
        <table className="table" >
            <tbody>
                {
                    options.map((option, index) => {
                        const classList = [];
                        selectedTag === option.tagValue && classList.push("selected-label");
                        disabled && classList.push("disabled")
                        return (
                            <tr key={index}
                                className={classList.join(" ")}
                                onClick={() => {
                                if (!(typeof onClick === "function")) {
                                    return;
                                }
                                // classList.push("selected-label");
                                onClick(option.keyValue);
                            }}
                            >
                                <td value={option.tagValue} >{option.tag}</td>
                                <td><button className={(option.keyValue ? "key buttonKey" : "key emptyBtn")} value={option.keyValue}>{option.key}</button></td>
                            </tr>
                        )
                    }
                    )}
            </tbody>
        </table>
    )
}

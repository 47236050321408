import React from 'react'
import Menu from './Menu'
import "./menuSection.css";
import FolderIcon from '@mui/icons-material/Folder';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CreateIcon from '@mui/icons-material/Create';
export default function MenuSection({ index }) {
    const menus = [
        {
            link: "/",
            name: "Prime table",
            icon: <FolderOutlinedIcon />,
            icon1: <FolderIcon />
        },
        {
            link: "/smart-table",
            name: "Smart table",
            icon: <FolderOutlinedIcon />,
            icon1: <FolderIcon />
        },
        {
            link: "/edit-smart-tables",
            name: "edit smart tables",
            icon: <CreateOutlinedIcon />,
            icon1: <CreateIcon />
        },
        {
            link: "/keys",
            name: "Keys",
            icon: <FolderOutlinedIcon />,
            icon1: <FolderIcon />
        },
        {
            link: "/edit-keys",
            name: "edit keys",
            icon: <CreateOutlinedIcon />,
            icon1: <CreateIcon />
        },
    ]

    return (
        <nav className='navigation'>
            {
                menus.map((menu, i) => {
                    return(
                        <Menu
                            key={i}
                            href={menu.link}
                            name={menu.name}
                            icon={menu.icon}
                            icon1={menu.icon1}
                            isActive={index === i}
                        />
                    )
                })
            }
        </nav>
    )
}

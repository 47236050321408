import "./dropDown.css";

export default function DropDown({ options, onChange, value, disabled }) {
    const classList = ["drop-down"];
    disabled && classList.push("disabled");

    return (
        <div>
            <select className={classList.join(" ")} onChange={(e) => {
                if (!(typeof onChange === "function")) {
                    return;
                }
                onChange(e);
            }} value={value}>
                {
                    options.map((option, index) => {
                        return (
                            <option
                                key={index}
                                value={option.value} >
                                {option.title} </option>
                        )
                    })
                }
            </select>
        </div>
    )
}

import './App.css';
// import Buttons from './Buttons';
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MenuSection from './MenuSection';
import { useEffect, useState } from 'react';
// import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
// import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
// import NewKeys from './NewKeys';
// import ToggleButton from './ToggleButton';
// import DropDown from './DropDown';
import TablePage from './TablePage';
import SmartPage from './SmartPage';
import EditSmartTable from './EditSmartTable';
import Keys from './Keys';
import EditKey from './EditKey';

function App() {
  const [index, setIndex] = useState();
  useEffect(() => {
    if (window.location.pathname === "/") {
      setIndex(0)
    }
    else if (window.location.pathname === "/smart-table") {
      setIndex(1)
    }
    else if (window.location.pathname === "/edit-smart-tables") {
      setIndex(2)
    }
    else if (window.location.pathname === "/keys") {
      setIndex(3)
    }
    else if (window.location.pathname === "/edit-keys") {
      setIndex(4)
    }
  }, [])
  // const [toggle, setToggle] = useState(true);
  // const handleClick = () => {
  //   setToggle(!toggle);
  // }
  return (
    <BrowserRouter>
      <div className="App">
        {/* <Buttons name={"import"} icon={<FileDownloadOutlinedIcon />} />
          <Buttons icon={<UndoOutlinedIcon />} />
          <Buttons icon={<RedoOutlinedIcon />} />
          <DropDown /> */}
        <div className='item1'>
          <MenuSection index={index} />
        </div>
        <Routes>
          <Route path='/' element={
            <div className='item2'>
              <TablePage />
            </div>
          } />
          <Route path='/smart-table' element={<SmartPage />} />
          <Route path='/edit-smart-tables' element={<EditSmartTable />} />
          <Route path='/keys' element={<Keys />} />
          <Route path='/edit-keys' element={<EditKey />} />
        </Routes>
        {/* <ToggleButton toggle={toggle} handleToggleChange={handleClick} />
          <NewKeys /> */}
      </div>
    </BrowserRouter>
  );
}

export default App;

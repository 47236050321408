import "./menu.css";

export default function Menu({ href, name, icon, icon1, isActive }) {
    const classList = ["menu"]
    const imgClass1 = [];
    const imgClass2 = ["imgHidden"];
    isActive && classList.push("activeMenu")
    isActive && imgClass1.push("imgHidden")
    isActive && imgClass2.pop("imgHidden")
    return (
        <div className={classList.join(" ")}>
            {/* <a href={href}><img className={imgClass1.join(" ")} src={icon} alt='' /> <img className={imgClass2.join(" ")} src={icon1} alt='' /> {name}</a> */}
            <a href={href}><div className={imgClass1.join(" ")}>{icon} </div>
                <div className={imgClass2.join(" ")}>{icon1} </div>
                {name}</a>
        </div>
    );
}

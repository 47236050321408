import "./keys.css"
import Buttons from './Buttons'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useEffect, useState } from "react";
import NewKeys from "./NewKeys";

export default function Keys() {
    const [keys, setKeys]= useState([]);
    useEffect(()=>{
        // const endpoint = "http:///api/keys/list";
        const endpoint = new URL('/api/keys/list');
        const params = new URLSearchParams();
        params.set('statusList', JSON.stringify(["active"]));
        params.set('limit', "100");
        endpoint.search = new URLSearchParams(params).toString();
        fetch(endpoint)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            setKeys(data);
        });
    },[])
    return (
        <div className="keys-container">
            <div className='top-div'>
                <Buttons name={"edit"} icon={<ModeEditOutlineOutlinedIcon />} />
                <Buttons name={"new"} icon={<AddBoxOutlinedIcon />} />
            </div>
            <div className='keys-body'>
            {
                    <NewKeys options={keys.map(key => ({value: key.keyId, name: key.keyName}))}  />
            }
            </div>
        </div>
    )
}

import "./edit-key.css";
import DropDown from './DropDown'
import { useEffect, useState } from "react";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import TableInfo from "./TableInfo";
import KeyInput from "./KeyInput";

export default function EditKey() {
    const [tables1, setTables1] = useState([]);
    const [tables2, setTables2] = useState([]);
    const [tags1, setTags1] = useState([]);
    const [selectedTag1, setSelectedTag1] = useState("");
    const [areTagsLoading, setAreTagsLoading] = useState(true);
    const [tags2, setTags2] = useState([]);
    const [selectedTag2, setSelectedTag2] = useState("");
    const [actionName, setActionName] = useState("")
    const [tagsUpdated, setTagsUpdated] = useState(false);
    const [newKey, setNewKey] = useState("");
    const [selectedTable1, setSelectedTable1] = useState("");
    const [selectedTable2, setSelectedTable2] = useState("");

    const actions = [
        {
            value: "link-old",
            name: "attach to old key"
        },
        {
            value: "link-new",
            name: "attach to new key"
        },
        {
            value: "unlink",
            name: "Delete key"
        }
    ]
    function displayTables(setTable) {
        const params = new URLSearchParams();
        params.set('statusList', JSON.stringify(["convert-complete"]));
        params.set('limit', "100");
        const target = '/api/tables/list?' + params.toString();
        fetch(target)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setTable(data);
            });
    }
    useEffect(() => {
        displayTables(setTables1)
    }, []);
    useEffect(() => {
        displayTables(setTables2)
    }, []);
    const fetchTableInfo = (tableId, index) => {
        if (tableId === "") return;
        setAreTagsLoading(true);
        const params = new URLSearchParams();
        params.set('tableId', tableId);
        const target = '/api/tables/info?' + params.toString();
        fetch(target)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                switch (index) {
                    case 0:
                        setTimeout(() => {
                            setTags1(data.tableInfo.schema);
                            setAreTagsLoading(false);
                        }, 1000)
                        break;
                    case 1:
                        setTimeout(() => {
                            setTags2(data.tableInfo.schema);
                            setAreTagsLoading(false);
                        }, 1000)
                        break;
                    default:
                        break;
                }
            });
    }
    useEffect(() => {
        console.log(selectedTable1);
        fetchTableInfo(selectedTable1, 0)
    }, [selectedTable1, tagsUpdated]);
    useEffect(() => {
        fetchTableInfo(selectedTable2, 1)
    }, [selectedTable2]);

    const handleChange = (e) => {
        setActionName(e.target.value);
    }

    function confirmOnClick() {
        switch (actionName) {
            case "link-new":
                const endpoint1 = '/api/labels/link';
                fetch(endpoint1, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'labelId': selectedTag1, 'newKeyName': newKey }),
                })
                    .then(response => response).then(data => {
                        alert('Successfully linking with new key!')
                        setTagsUpdated((p) => !p);
                        setActionName("");
                        setNewKey("");

                    }).catch(error => {
                        console.error(error);
                        alert('error linking with new key!')
                    });
                break;
            case "link-old":
                const endpoint2 = '/api/labels/link';
                fetch(endpoint2, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'labelId': selectedTag1, 'oldKeyId': tags2.find(tag => tag.labelId === selectedTag2).keyId }),
                })
                    .then(response => response).then(data => {
                        alert('Successfully linking with old key!')
                        setTagsUpdated((p) => !p);
                        setActionName("");
                        setTags2([])
                    }).catch(error => {
                        console.error(error);
                        alert('Error linking with old key!')
                    });

                break;
            case "unlink":
                const endpoint3 = '/api/labels/unlink';
                fetch(endpoint3, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'labelId': selectedTag1 }),
                })
                    .then(response => response).then(data => {
                        alert('Successfully unlinking label!')
                        setTagsUpdated((p) => !p);
                        setActionName("");
                    }).catch(error => {
                        console.error(error);
                        alert('Error unliking lable!')
                    });
                break;
            default:
                break;
        }

    }
    return (
        <div className="edit-key">
            <div className='top-div'>
                <div>
                    <p>action</p>
                    {
                        <DropDown
                            options={[{ title: "Choose Action", value: "" }].concat(actions.map((action, index) => ({ title: action.name, key: index, value: action.value })))}
                            onChange={handleChange}
                            value={actionName}
                        />
                    }
                </div>
                <button className="btn" hidden={(actionName === "")} onClick={confirmOnClick}><CheckBoxOutlinedIcon /> Confirm</button>
            </div>
            <div className='top-div'>
                <div>
                    <p>1st table</p>
                    {
                        !!(tables1.length) &&
                        <DropDown options={[{ title: "Choose Table", value: "" }].concat(tables1.map((table, index) => ({ title: table.tableName, key: index, value: table.tableId })))}
                            onChange={(e) => setSelectedTable1(e.target.value)}
                            value={selectedTable1}
                        />
                    }
                </div>
                <div>
                    {
                        !!(tags1.length) && !(selectedTable1 === "") &&
                        <>
                            <p>1st tag</p>
                            <DropDown
                                disabled={areTagsLoading}
                                options={[{ title: "Choose Tag", value: "" }].concat(tags1.map(label => ({ title: label.labelName, value: label.labelId })))}
                                value={selectedTag1}
                                onChange={(e) => { setSelectedTag1(e.target.value) }}
                            />
                        </>
                    }
                </div>
                <div>
                    {
                        !!(tables2.length) && !(selectedTable1 === "") && !(selectedTag1 === "") && (actionName === "link-old") &&
                        <>
                            <p>2st table</p>
                            <DropDown options={[{ title: "Choose Table", value: "" }].concat(tables2.filter((t) => (t._id !== selectedTable1)).map((table, index) => ({ title: table.tableName, key: index, value: table.tableId })))}
                                onChange={(e) => setSelectedTable2(e.target.value)}
                                value={selectedTable2}
                            />
                        </>
                    }
                </div>
                <div>
                    {
                        !!(tags2.length) && !(selectedTable2 === "") && (actionName === "link-old") &&
                        <>
                            <p>2st tag</p>
                            <DropDown
                                disabled={areTagsLoading}
                                options={[{ title: "Choose Tag", value: "" }].concat(tags2.map(label => ({ title: label.labelName, value: label.labelId, keyId: label.keyId })))}
                                value={selectedTag2}
                                onChange={(e) => { setSelectedTag2(e.target.value) }}
                            />
                        </>
                    }
                </div>
                <div>
                    {
                        !(selectedTable1 === "") && !(selectedTag1 === "") && (actionName === "link-new") &&
                        <>
                            <p>type new key</p>
                            <KeyInput value={newKey} handleChange={(id) => setNewKey(id)} />
                        </>
                    }
                </div>
            </div>
            <div className="tables">
                <div>
                    {
                        !!(tags1.length) && !(selectedTable1 === "") &&
                        <>
                            <p>1st tagline</p>
                            <TableInfo
                                disabled={areTagsLoading}
                                options={tags1.map(tag => ({ tag: tag.labelName, key: tag.keyName || "no key", tagValue: tag.labelId, keyValue: tag.keyId, }))}
                                selectedTag={selectedTag1}
                            />
                        </>
                    }
                </div>
                <div>
                    {
                        !!(tags2.length) && !(selectedTable2 === "") &&
                        <>
                            <p>2st tagline</p>
                            <TableInfo
                                disabled={areTagsLoading}
                                options={tags2.map(tag => ({ tag: tag.labelName, key: tag.keyName || "no key", tagValue: tag.labelId, keyValue: tag.keyId }))}
                                selectedTag={selectedTag2} />
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
